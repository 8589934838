
import { heroShape } from '@/types/hero';

export default {
  inheritAttrs: false,
  props: {
    ...heroShape,
  },
  computed: {
    visualProps() {
      const visual = this.$first(this.visual);

      if (visual.kind === 'video') {
        return {
          ...visual,
          muted: true,
        };
      }
      return visual;
    },
  },
};
