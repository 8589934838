import { string, shape, arrayOf, oneOf } from 'vue-types';

import { visualList } from '@/types/visual';

export const heroShape = {
  heading: string(),
  quote: string(),
  quoteAlignment: oneOf(['left', 'center', 'right']).def('left'),
  visual: visualList,
};

export const hero = shape(heroShape).loose;
export const heroList = arrayOf(hero);
