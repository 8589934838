
import pageMixin from '@/mixins/page';
import homepageQuery from '@/gql/queries/homepage.gql';

export default {
  name: 'Home',
  mixins: [pageMixin],
  asyncData({ $cms, i18n }) {
    return $cms.query({
      query: homepageQuery,
      variables: { site: i18n.locale },
      mandatoryKeys: ['entry'],
    });
  },
  computed: {
    heroProps() {
      return this.$first(this.entry?.hero);
    },
  },
};
